<template>
  <div>
    <a-layout>
      <div
        class="yj-conten"
        style="
          background-color: #fff;
          width: calc(100% - 32px);
          min-width: fit-content;
        "
      >
        <a-layout-content style="padding: 0">
          <div
            class="yj-conten"
            style="background: #fff; padding: 20px 20px 0; margin: 0"
          >
            <a-row class="fm" :gutter="10" type="flex" justify="space-between">
              <a-col :span="12">
                <a-button type="primary" @click="ShowAddSpecification()" 
                  >新增商品规格</a-button
                >
              </a-col>
              <div>
                <a-modal
                  v-model="visible"
                  title="新增规格"
                  :confirm-loading="confirmLoading"
                  @ok="handleOk"
                  @cancel="handleCancel"
                >
                  <a-input placeholder="规格名称" v-model="SFName" />
                </a-modal>
              </div>
            </a-row>
            <div style="padding: 20px 0px">
              <a-table
                :columns="columns"
                bordered
                rowKey="ID"
                :data-source="DataList"
                :pagination="pagination"
                @change="pagerChange"
                style="min-height: calc(100vh - 234px); min-width: max-content"
                class="yj-table-img"
              >
                <sapn slot="action" slot-scope="text, record">
                  <a href="#" @click="onEdit(record.ID, record.SFName)">编辑</a>
                  <!--<a-divider type="vertical" />
                  <a href="#" @click="onDelete(record.ID)">删除</a>-->
                </sapn>
              </a-table>
            </div>
          </div>
        </a-layout-content>
      </div>
    </a-layout>
  </div>
</template>
<script>
import http from "@/Plugin/Http.js";
import util from "@/Plugin/util.js";
const columns = [
  {
    title: "编号",
    dataIndex: "ID",
    align: "center",
    width: 30,
  },
  {
    title: "规格名称",
    dataIndex: "SFName",
    alert: "center",
    width: 50,
  },
  {
    title: "录入时间",
    dataIndex: "InputTime",
    alert: "center",
    width: 100,
    customRender: (text, item) => {
      return util.ChangeDateFormat(item.InputTime);
    },
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 100,
  },
];
export default {
  name: "SpecificationList",
  data() {
    return {
      EditID: null, //编辑ID
      SFName: null, //规格名称
      visible: false, //显示添加对话框
      confirmLoading: false, //按钮加载中样式
      columns,
      DataList: [],
      pagination: {
        total: 0,
        defaultPageSize: 0,
        current: 1,
        showTotal: (total) => `共 ${total} 条数据`,
      },
    };
  },
  //方法
  methods: {
    //显示新增规格列表
    ShowAddSpecification() {
      this.visible = true;
    },
    //单击Ok键
    handleOk(e) {
      console.log(e);
      if (this.EditID != null) {
        this.Edit();
        return 1;
      }
      (this.SFName && (this.Add(), (this.confirmLoading = true), 1)) ||
        this.$message.warning("名称不能为空!");
    },
    //单击取消
    handleCancel() {
      this.confirmLoading = false;
      this.visible = false;
      this.SFName = null;
      this.EditID=null;
    },
    //新增规格
    Add() {
      let self = this;
      var op = {
        url: "/ShopModule/Product/AddSpecification",
        data: { SFName: self.SFName },
        OnSuccess: function (res) {
          console.log(res);
          self.confirmLoading = false;
          res.status == 200 &&
            ((self.visible = false),
            (self.SFName = null),
            (self.EditID=null),
            self.GetSpecificationList(),
            1);
        },
      };
      http.Post(op);
      setTimeout(function () {
        self.confirmLoading = false;
        // self.SFName = null;
        // self.EditID=null;
      }, 5000); //5秒自动关闭按钮加载中事件,清空规格名称
    },
    //删除规则规格
    onDelete(id) {
      console.log(id);
    },
    onEdit(id, name) {
      this.EditID = id;
      this.SFName = name;
      this.visible = true;
    },
    //编辑规格
    Edit() {
      let self = this;
      var op = {
        url: "/ShopModule/Product/Ac_EditSpecification",
        data: { ID: self.EditID, SFName: self.SFName },
        OnSuccess: function (res) {
          console.log(res);
          self.confirmLoading = false;
          res.status == 200 &&
            ((self.visible = false),
            (self.SFName = null),
            (self.EditID=null),
            self.GetSpecificationList(),
            1);
        },
      };
      http.Post(op);
      setTimeout(function () {
        self.confirmLoading = false;
        // self.SFName = null;
        // self.EditID=null;
      }, 5000); //5秒自动关闭按钮加载中事件,清空规格名称
    },
    //获取机构规格列表
    GetSpecificationList() {
      var self = this;
      var op = {
        url: "/ShopModule/Product/Ac_GetSpecificationList",
        data: { IndexPage: 1 },
        OnSuccess: function (res) {
          console.log(res);
          if (res.status == 200) {
            //请求成功
            self.DataList = res.data.Data;
            self.pagination.total = res.data.Total;
            self.pagination.defaultPageSize = res.data.RowCount;
            self.pagination.current = res.data.PageIndex;
          }
        },
      };
      http.Post(op);
    },
    //页码事件
    pagerChange: function (item) {
      var self = this;
      self.pagination.current = item.current;
      self.GetSpecificationList();
    },
  },
  //生命周期，组装完成
  mounted() {
    //获取机构规格列表
    this.GetSpecificationList();
  },
};
</script>
